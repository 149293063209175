import {StyleExtractor} from "@kubric/utils";
import {graphql} from "gatsby";
import {OutboundLink} from "gatsby-plugin-google-analytics";
import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";

import LastSection from "../../components/AboveFooterSection";
import Buffer from "../../components/Buffer";
import Layout from "../../components/Layout";
import StructuredData from "../../components/StructuredData";
import EmailCTA from "../../components/commons/EmailCTA";
import StickyCTA from "../../components/pages/modemagic/StickyCTA";
import {commonJoinList} from "../../lib/actions";
import * as styles from "./styles.module.scss";

const styler = new StyleExtractor(styles);

const BGFiller = ({className}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    viewBox='0 0 1440 88'
    fill='none'
  >
    <path d='M0 0H1440L0 88V0Z' fill='#F3F6F6' />
  </svg>
);

const EbookItem = ({description, ctaUrl, ctaText, title, image}) => (
  <div className={styler.get("ebook")}>
    <GatsbyImage
      image={image?.image?.asset?.gatsbyImageData}
      className={styler.get("image")}
      alt={image?.alt}
    />
    <div className={styler.get("info")}>
      <h3>{title}</h3>
      <p>{description}</p>
      <OutboundLink href={ctaUrl} target='_blank' rel='noopener noreferrer'>
        {ctaText}
      </OutboundLink>
    </div>
  </div>
);

const EbooksPage = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  const {
    description,
    cta = "Subscribe",
    heading,
    placeholder = "Enter email",
    ebooks = [],
  } = data.allSanityEbooksPage.nodes[0] || {};

  return (
    <Layout
      seoData={seoData}
      headerProps={{alternateBg: true}}
      className={styler.get("body")}
    >
      <StructuredData />
      <Buffer buffer={81} mobileBuffer={30} />

      {/* Hero Section */}
      <section className={styler.get("heroWrap", "full-bleed")}>
        <h1 className={styler.get("heading")}>{heading}</h1>
        <p className={styler.get("description")}>{description}</p>
        <EmailCTA
          id='subscribe-to-blog'
          data={{
            cta,
            placeholder,
          }}
          theme={{
            container: styler.get("ctaContainer"),
          }}
          propOnClick={({email}) => {
            return commonJoinList({
              tags: ["ebook-subscribers"],
              email,
              list: "others",
              account: "mailchimp",
            });
          }}
        />
      </section>

      {/* BG Filler */}
      <BGFiller className={styler.get("bgFiller", "full-bleed")} />

      {/* Plans Section */}
      <section className={styler.get("ebooksWrap", "full-bleed")}>
        {ebooks.map((ebook) => (
          <EbookItem {...ebook} key={ebook._key} />
        ))}
      </section>

      {/* Footer Section */}
      <section className={styler.get("footerWrap", "full-bleed")}>
        <LastSection hideTicketSection />
      </section>

      {/* Sticky CTA for mobile */}
      <StickyCTA
        isEmailCTA
        className={styler.get("hideInDesktop")}
        id='subscribe-to-blog'
        propOnClick={({email}) => {
          return commonJoinList({
            tags: ["ebook-subscribers"],
            email,
            list: "others",
            account: "mailchimp",
          });
        }}
        hideEmailInMobile={false}
        cta={cta}
        emailPlaceholder={placeholder}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "ebooks"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
    allSanityEbooksPage {
      nodes {
        description
        cta
        heading
        placeholder
        ebooks {
          _key
          description
          ctaUrl
          ctaText
          title
          image {
            alt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default EbooksPage;
