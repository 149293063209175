// extracted by mini-css-extract-plugin
export var bgFiller = "styles-module--bgFiller--39MQB";
export var body = "styles-module--body--3iE9C";
export var ctaContainer = "styles-module--ctaContainer--YloHc";
export var description = "styles-module--description--2rDie";
export var ebook = "styles-module--ebook--dseDY";
export var ebooksWrap = "styles-module--ebooksWrap--3XfhG";
export var footerWrap = "styles-module--footerWrap--2NT1n";
export var heading = "styles-module--heading--1acIA";
export var heroWrap = "styles-module--heroWrap--22Pi7";
export var hideInDesktop = "styles-module--hideInDesktop--2KzPr";
export var hideInMobile = "styles-module--hideInMobile--gQKMY";
export var image = "styles-module--image--3Rg3p";
export var info = "styles-module--info--2SG7S";